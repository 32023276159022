 
.data-grid-container .data-grid {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell {
  height: 38px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 0;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid red;
  text-align: right;
  width: calc(100% - 6px);
  height: 38px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  padding-left: 15px;
  font-size: 1.2em;
  text-align: left;
}