.list-group-item.active {
    z-index: 0;
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4;
  }




  /* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.6s;
  cursor: pointer;
}
.ripple:hover {
  /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%; */
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}



 /* Block UI */
.block-ui-message {
  color: #333;
  background: none;
  font-size:  32px;
  z-index: 1011;
}

.block-ui-overlay {
  opacity: 0.8;
}

/* span.hover-underline {
  text-decoration: underline;
  font-size : 14px
} */
span.hover-underline:hover {
  text-decoration: underline;
  cursor:  'pointer'
}