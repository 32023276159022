.list-group-item.active {
    z-index: 0;
    color: #FFFFFF;
    background-color: #4285f4;
    border-color: #4285f4;
  }




  /* Ripple effect */
.ripple {
  background-position: center;
  -webkit-transition: background 0.6s;
  transition: background 0.6s;
  cursor: pointer;
}
.ripple:hover {
  /* background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%; */
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  -webkit-transition: background 0s;
  transition: background 0s;
}



 /* Block UI */
.block-ui-message {
  color: #333;
  background: none;
  font-size:  32px;
  z-index: 1011;
}

.block-ui-overlay {
  opacity: 0.8;
}

/* span.hover-underline {
  text-decoration: underline;
  font-size : 14px
} */
span.hover-underline:hover {
  text-decoration: underline;
  cursor:  'pointer'
}
 
.data-grid-container .data-grid {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.data-grid-container .data-grid .cell {
  height: 38px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: cell;
  background-color: unset;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  vertical-align: middle;
  text-align: right;
  border: 1px solid #DDD;
  padding: 0;
}

.data-grid-container .data-grid .cell > input {
  outline: none !important;
  border: 2px solid red;
  text-align: right;
  width: calc(100% - 6px);
  height: 38px;
  background: none;
  display: block;
}

.data-grid-container .data-grid .cell .value-viewer, .data-grid-container .data-grid .cell .data-editor {
  display: block;
  padding-left: 15px;
  font-size: 1.2em;
  text-align: left;
}
